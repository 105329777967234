@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-5xl font-bold text-slate-700;
  }
  h2 {
    @apply text-4xl font-bold text-slate-700;
  }
  h3 {
    @apply text-3xl font-bold text-slate-700;
  }
  h4 {
    @apply text-2xl font-bold text-slate-700;
  }
  h5 {
    @apply text-xl font-bold text-slate-700;
  }
  h6 {
    @apply text-lg font-medium text-slate-700;
  }
}

@layer components {
  .container {
    @apply mx-auto w-full px-4 sm:max-w-screen-sm sm:px-6 md:max-w-screen-md lg:max-w-screen-lg lg:px-8 xl:max-w-screen-xl 2xl:max-w-screen-xl;
  }

  .bg-glassy {
    background-color: rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  .error-sm {
    @apply text-sm font-medium text-red-500;
  }

  .header-dropdown-container {
    @apply grid;
    grid-template-rows: min-content 1px;
    justify-items: end;
  }

  .gradient-primary {
    @apply from-blue bg-gradient-to-br via-blue-700 to-green-100 bg-clip-text text-transparent;
  }

  .hero-background-pattern {
    background: linear-gradient(90deg, #ffffff 18px, transparent 1%) center,
      linear-gradient(#ffffff 18px, transparent 1%) center, #f3f4f6;
    background-size: 22px 22px;
  }

  .centerCard {
    @apply scale-50 transform-gpu;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  ::selection {
    @apply bg-ifixers-50 bg-opacity-90 text-ifixers;
  }

  /* Snowflake styling */
  .snowflake {
    position: fixed;
    top: -10px; /* Start slightly above the viewport */
    background: white;
    border-radius: 50%;
    pointer-events: none;
    z-index: 0; /* Behind the content */
    animation: fall linear infinite, sway ease-in-out infinite;
  }

  /* Falling animation */
  @keyframes fall {
    0% {
      top: -10px; /* Start above the viewport */
    }
    100% {
      top: 100vh; /* Fall beyond the bottom of the viewport */
    }
  }

  /* Swaying animation */
  @keyframes sway {
    0%,
    100% {
      transform: translateX(0); /* No horizontal movement at start/end */
    }
    50% {
      transform: translateX(40px); /* Sway left/right */
    }
  }
}
